import React, {useCallback, useRef} from 'react';
import {FaceLivenessDetector} from '@aws-amplify/ui-react-liveness';
import {Loader, ThemeProvider} from '@aws-amplify/ui-react';
import {Modal} from "./components/Modal/Modal";
import Webcam from "react-webcam";

interface ResultProps {
    Confidence: number;
    Status: "SUCCEEDED";
    ReferenceImage: any
}

interface DocumentComparisonResult {
    Similarity: number
    Document: string
}

export function App() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [sessionId, setSessionId] = React.useState<string | null>(null);
    const [result, setResult] = React.useState<ResultProps | null>(null);
    const [documentComparisonResult, setDocumentComparisonResult] = React.useState<DocumentComparisonResult | null>(null);
    const [imageData, setImageData] = React.useState<ResultProps | null>(null)
    const [isDocumentModalOpen, setIsDocumentModalOpen] = React.useState(false)
    const [imgSrc, setImgSrc] = React.useState(null);

    const webcamRef = useRef(null);

    const capture = useCallback(async () => {
        if (webcamRef.current) {
            // @ts-ignore
            const imageSrc = webcamRef.current?.getScreenshot()
            setImgSrc(imageSrc);
            await sendImage(imageSrc)
            setTimeout(() => {
                setIsDocumentModalOpen(false);
            }, 1000)
        }
    }, [webcamRef, result, imageData]);

    const retakeSnap = () => {
        setImgSrc(null);
    };

    const sendImage = async (image: string) => {
        const formattedImageData = image.replace('data:image/jpeg;base64,', '');
        await fetch('https://zmwi329hil.execute-api.ap-northeast-1.amazonaws.com/dev/ocr', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Document: {
                    image: formattedImageData
                },
                Face: {
                    Bucket: imageData?.ReferenceImage?.S3Object?.Bucket ?? null,
                    Key: imageData?.ReferenceImage?.S3Object?.Name ?? null,
                }
            })
        }).then((response: Response) => response.json())
            .then((data: DocumentComparisonResult) => setDocumentComparisonResult(data))
            .catch((error: Error) => console.error(error))
    }

    const fetchImages = async () => {
        const requestPath = `https://zmwi329hil.execute-api.ap-northeast-1.amazonaws.com/dev/result/${sessionId}`

        await fetch(requestPath)
            .then((response: Response) => response.json())
            .then((data: ResultProps) => setImageData(data))
            .catch((error: Error) => console.error(error))
    }

    const fetchCreateLiveness = async () => {
        const url = 'https://zmwi329hil.execute-api.ap-northeast-1.amazonaws.com/dev/session';

        const data = await fetch(url, {
            headers: {
                'Content-Type': 'text/plain'
            },
        }).then((res) => {
            return res.text();
        }).then((data) => {
            const session = data.replace(/["']/g, "")
            console.log(session);
            return session;
        })

        setSessionId(data);
        setLoading(false);
    };

    React.useEffect(() => {
        fetchCreateLiveness();
    }, []);

    const handleAnalysisComplete = async () => {

        // return new Promise(resolve => {} ).then(() => {});
        /*
         * This should be replaced with a real call to your own backend API
         */
        const response = await fetch(
            `https://zmwi329hil.execute-api.ap-northeast-1.amazonaws.com/dev/result/${sessionId}`
        );
        const data = await response.json();
        setResult(data);
        console.log('AnalysisComplete', data);

        await fetchImages();

        /*
         * Note: The isLive flag is not returned from the GetFaceLivenessSession API
         * This should be returned from your backend based on the score that you
         * get in response. Based on the return value of your API you can determine what to render next.
         * Any next steps from an authorization perspective should happen in your backend and you should not rely
         * on this value for any auth related decisions.
         */
    };

    const handleDocumentVerification = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsDocumentModalOpen(true);
    }

    const renderSuccessPage = () => {
        return (
            <div style={{
                width: "100wh",
                height: "100vh",
                fontSize: '38px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Modal isOpen={isDocumentModalOpen} onClose={() => setIsDocumentModalOpen(false)}>
                    <div style={{
                        width: 'auto',
                        height: 'auto',
                        background: '#FFF',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        overflow: 'hidden'
                    }}>
                        <div style={{
                            border: '2px solid #000',
                            height: '450px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            {imgSrc ? (
                                <img src={imgSrc} alt="webcam" />
                            ) : (
                                <Webcam
                                    height={450}
                                    width={'auto'}
                                    ref={webcamRef}
                                    screenshotQuality={1}
                                    forceScreenshotSourceSize
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={{
                                        facingMode: 'environment'
                                    }}
                                />
                            )}
                        </div>
                        { imgSrc ? <span style={{ textAlign: 'center', color: '#48e028' }}>Snapshot successfully taken </span> : ''}
                        <button
                            onClick={capture}
                            style={{
                                height: 'auto',
                                padding: '5px 10px',
                                width: 'auto',
                                textAlign: 'center',
                                background: '#14ad0f',
                                color: '#FFFFFF',
                                fontSize: '22px',
                                border: 'none',
                                borderRadius: '12px',
                                cursor: 'pointer',
                            }}>
                            Screenshot
                        </button>
                        <button
                            onClick={retakeSnap}
                            style={{
                                height: 'auto',
                                padding: '5px 10px',
                                width: 'auto',
                                textAlign: 'center',
                                background: '#EA5347',
                                color: '#FFFFFF',
                                fontSize: '22px',
                                border: 'none',
                                borderRadius: '12px',
                                cursor: 'pointer',
                            }}>
                            Retake
                        </button>
                    </div>
                </Modal>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}
                >
                    <span>{result?.Status}</span>
                    <span style={{ marginBottom: '20px' }}>liveness = {result?.Confidence}</span>
                        { imgSrc && !documentComparisonResult ? <span style={{ marginBottom: '20px', fontSize: '24px' }}>Screenshot submitted and awaiting verification</span> : ''}
                        { documentComparisonResult &&
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                <span style={{ marginBottom: '20px' }}>Document Similarity: {documentComparisonResult.Similarity}</span>
                                <span style={{ marginBottom: '20px' }}>FIO: {documentComparisonResult.Document}</span>
                            </div>
                        }
                    { !documentComparisonResult &&
                        <button
                            onClick={handleDocumentVerification}
                            style={{
                                height: 'auto',
                                padding: '10px 20px',
                                width: 'auto',
                                textAlign: 'center',
                                background: '#EA5347',
                                color: '#FFFFFF',
                                fontSize: '28px',
                                border: 'none',
                                borderRadius: '12px',
                                cursor: 'pointer',
                            }}>
                            Verify ID
                        </button>
                    }
                </div>
            </div>
        )
    }

    return (
        <ThemeProvider>
            {loading ? (
                <Loader/>
            ) : (
                <div>
                    {
                        result ?
                            renderSuccessPage()
                            :
                            <FaceLivenessDetector
                                key={sessionId as unknown as string}
                                sessionId={sessionId as unknown as string}
                                region="ap-northeast-1"
                                onAnalysisComplete={handleAnalysisComplete}
                                onUserCancel={() => {
                                    console.log('User cancelled');
                                    fetchCreateLiveness();
                                }}
                                disableInstructionScreen={true}
                            />


                    }
                </div>
            )}
        </ThemeProvider>
    );
}

export default App;