import React, {Fragment, ReactNode, useEffect, useRef, useState} from "react";
import { ReactPortal } from "../ReactPortal";
import cn from 'classnames';
import './style.css';

interface Props {
    isOpen: boolean
    children: ReactNode
    onClose: () => void
}
export const Modal = (props: Props) => {
    const [isActive, setIsActive] = useState(false);
    const { isOpen, children, onClose } = props;
    const backdrop = useRef(null);

    useEffect(() => {
        const { current } = backdrop;

        const transitionEnd = () => {
            setIsActive(isOpen);
        }

        const keyHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        if (current) {
            (current as HTMLElement).addEventListener("transitionend", transitionEnd);
            window.addEventListener("keyup", keyHandler);
        }

        if (isOpen) {
            window.setTimeout(() => {
                (document.activeElement as HTMLElement).blur();
                setIsActive(isOpen);
                document.querySelector("#root")!.setAttribute("inert", "true");
            });
        }

        return () => {
            if (current) {
                (current as HTMLElement).removeEventListener("transitionend", transitionEnd);
            }
            document.querySelector("#root")!.removeAttribute("inert");
            window.removeEventListener("keyup", keyHandler);
        };
    }, [isOpen, onClose]);

    const handleClose = (event: any) => {
        if (event.target.dataset?.overlay) {
            onClose();
        }
    }

    return (
        <Fragment>
            {(isOpen || isActive) && (
                <ReactPortal className="modal__portal">
                    <div
                        onClick={handleClose}
                        ref={backdrop}
                        data-overlay="overlay"
                        className={cn({
                            'modal__container': true,
                            'modal__container--active': isActive && isOpen
                        })}
                    >
                        <div className="modal__content">
                            {children}
                        </div>
                    </div>
                </ReactPortal>
            )}
        </Fragment>
    );
}
