import { createPortal } from 'react-dom';
import {ReactNode, useEffect, useMemo} from "react";

interface Props {
    children: ReactNode
    parent?: HTMLElement
    className: string
}

export const ReactPortal = ({ children, parent, className }: Props) => {
    const element = useMemo(() => document.createElement("div"), []);

    useEffect(() => {
        const target = parent ? parent : document.body;
        const classList = ["portal-container"];

        if (className) {
            className.split(" ").forEach((item) => classList.push(item));
        }

        classList.forEach((item) => element.classList.add(item));
        target.appendChild(element);

        return () => {
            target.removeChild(element);
        };
    }, [element, parent, className]);

    return createPortal(children, element);
}